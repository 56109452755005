<template>
  <div>
    <v-container>
      <v-row
        v-if="loggedUser.company_role === 'owner'"
        dense
      >
        <v-col
          cols="12"
        >
          <v-card>
            <v-card-title class="text-h5">
              La mia attività
            </v-card-title>

            <v-card-subtitle>
              Puoi modificare o inserire i dati della tua azienda
            </v-card-subtitle>

            <v-card-text>
              <v-form
                ref="formCompany"
                @submit.prevent="update"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="3"
                  >
                    <v-avatar
                      color="grey lighten-2"
                      size="100"
                      @click="uploadAvatarIsShow=true"
                    >
                      <v-img
                        v-if="companyAvatar"
                        :src="companyAvatar"
                      >
                      </v-img>
                      <span
                        v-else
                        class="text-h5"
                      >
                        {{ loggedUserCompany.initials }}
                      </span>
                    </v-avatar>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                  >
                    <v-text-field
                      v-model="loggedUserCompany.name"
                      label="Nome"
                      outlined
                      required
                      dense
                      :rules="[formRules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="loggedUserCompany.email"
                      label="Email aziendale"
                      outlined
                      required
                      dense
                      :rules="[formRules.emailRules,formRules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="loggedUserCompany.piva"
                      label="Partita Iva"
                      outlined
                      required
                      dense
                      :rules="[formRules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="loggedUserCompany.cf"
                      label="Codice Fiscale"
                      outlined
                      required
                      dense
                      :rules="[formRules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="loggedUserCompany.address"
                      label="Indirizzo sede"
                      outlined
                      required
                      dense
                      :rules="[formRules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="loggedUserCompany.city"
                      label="Città"
                      outlined
                      required
                      dense
                      :rules="[formRules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="loggedUserCompany.zip_code"
                      label="Cap"
                      outlined
                      required
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="loggedUserCompany.country"
                      label="Nazione"
                      outlined
                      required
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <v-card-actions
              class="justify-end mx-2 hidden-xs-only"
            >
              <v-btn
                color="primary"
                @click.prevent="update"
              >
                Salva
              </v-btn>
            </v-card-actions>
            <v-card-actions
              class="justify-end mx-2 hidden-sm-and-up"
            >
              <v-btn
                color="primary"
                block
                @click.prevent="update"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-card>
            <v-card-title class="text-h5">
              <span>Membri</span>
              <v-spacer></v-spacer>
              <v-btn
                v-if="loggedUser.company_role === 'owner'"
                color="primary"
                @click="inviteIsShow=true"
              >
                Invita nel team
              </v-btn>
            </v-card-title>

            <v-card-subtitle>
              Elenco dei membri di {{ loggedUserCompany.name }}
            </v-card-subtitle>

            <v-card-text>
              <v-list>
                <v-list-item
                  v-for="item in loggedUserCompany.users"
                  :key="item.id"
                >
                  <v-list-item-action>
                    <v-checkbox
                      input-value="active"
                      color="primary"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-avatar>
                    <procedure-users-avatar :user="item"></procedure-users-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content
                    class="ml-3"
                  >
                    <v-row>
                      <v-col
                        cols="4"
                      >
                        <v-list-item-title v-text="item.fullName"></v-list-item-title>
                        <small>{{ item.company_role }}</small>
                      </v-col>
                      <v-col
                        cols="8"
                      >
                        <v-simple-table>
                          <tr>
                            <td>{{ item.email }}</td>
                            <td>{{ item.phone1 }}</td>
                            <td>{{ item.phone2 }}</td>
                          </tr>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <upload-avatar
      :upload_url="uploadUrl"
      :is_show="uploadAvatarIsShow"
      @close="uploadAvatarIsShow=false"
      @reload-data="reloadData"
    ></upload-avatar>

    <invite-professional-company
      :is_show="inviteIsShow"
      :company-data="loggedUserCompany"
      @close="inviteIsShow=false"
    ></invite-professional-company>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UploadAvatar from '@/components/modals/upload-avatar'
import ProcedureUsersAvatar from '@/components/procedure/procedure-users-avatar'
import InviteProfessionalCompany from '@/components/modals/invite-professional-company'

export default {
  name: 'Profile',
  components: { UploadAvatar, ProcedureUsersAvatar, InviteProfessionalCompany },
  computed: {
    ...mapGetters(['formRules', 'loggedUser', 'loggedUserCompany', 'companyAvatar']),
  },
  data() {
    return {
      inviteIsShow: false,
      uploadAvatarIsShow: false,
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'La mia attività',
          to: '/company',
        },
      ],
      uploadUrl: `${this.$apiUrl}company-avatar`,
    }
  },
  mounted() {
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
  },
  methods: {
    async update() {
      if (this.$refs.formCompany.validate()) {
        try {
          await this.$api.updateCompany(this.loggedUserCompany.id, this.loggedUserCompany)
          await this.$store.dispatch('set_alert', { msg: 'Attività aggiornata', type: 'success' })
          await this.$store.dispatch('getCompanyInfo', this.loggedUserCompany.id)
        } catch (e) {
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
    async reloadData() {
      await this.$store.dispatch('getCompanyAvatar', this.loggedUserCompany.id)
    },
  },
}
</script>
